<template>
    <div class="container border-container">
        <div class="info-container">
            <span class="list-title">班级列表</span>
            <el-input v-if="false" class="circular-bead-input" placeholder="请输入班级名称" prefix-icon="el-icon-search" title="按回车键搜索"
                @keyup.enter.native="pageChange(1)" @clear="pageChange(1)" v-model='search.name' >
            </el-input>
        </div>
        <div class="table-container">
            <el-table :data="tableData" tooltip-effect="dark" style="width: 100%" size="medium"
                :header-cell-style="{height: '40px', background: 'linear-gradient(180deg,rgba(251,252,253,1) 0%,rgba(246,248,250,1) 100%)'}"
                :cell-style="{height: '50px',padding: '5px 0'}" header-align="center">
                <el-table-column label="班级名称" min-width="150" :show-overflow-tooltip="true" align="center">
                    <template slot-scope="scope">
                        <div class="fz-bold hover-text-colourful" @click="routerChange(1, scope.row)">
                            {{scope.row.name}}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="学生数" min-width="120" align="center">
                    <template slot-scope="scope">
                        {{scope.row.student_count}}
                    </template>
                </el-table-column>
                <!-- <el-table-column label="创建者" min-width="120" :show-overflow-tooltip="true" align="center">
                    <template slot-scope="scope">
                        {{scope.row.creator ? scope.row.creator.name : ''}}
                    </template>
                </el-table-column> -->
                <el-table-column label="创建时间" min-width="155" align="center">
                    <template slot-scope="scope">
                        {{scope.row.create_time | timeFilter}}
                    </template>
                </el-table-column>
                <el-table-column label="操作" min-width="100" align="center">
                    <template slot-scope="scope">
                        <!-- <el-button type="text" class="icon-btn" @click="handleDialogShow(scope.row)" v-if="auth('class.update')">
                            <el-tooltip placement="top" content="编辑班级信息">
                                <i class="iconfont icon-edit" />
                            </el-tooltip>
                        </el-button> -->
                        <el-button type="text" class="icon-btn" @click="handleDeleteClass(scope.row.id)" v-if="auth('class.update')">
                            <el-tooltip placement="top" content="删除">
                                <i class="iconfont icon-delete" />
                            </el-tooltip>
                        </el-button>
                        <el-button type="text" class="icon-btn" @click="routerChange(1, scope.row)">
                            <el-tooltip placement="top" content="详情">
                                <i class="iconfont icon-check" />
                            </el-tooltip>
                        </el-button>
                    </template>
                </el-table-column>
                <div class="" slot="empty">
                    <no-data></no-data>
                </div>
            </el-table>
        </div>
        <div class="page-container">
            <el-pagination layout="total, sizes, prev, pager, next" background
                @size-change="pageSizeChange"
                @current-change="pageChange"
                :current-page="currentPage"
                :page-sizes="[total, 10, 15, 20, 25]"
                :total="total" :page-size="pageSize">
            </el-pagination>
        </div>
        <!-- <el-dialog class="school-class-dialog"
            :title="classEdit.id ? '修改班级信息' : '新增班级'"
            :visible.sync="dialogVisible"
            :before-close="handleDialogClose">
            <div class="school-class-dialog-content">
                <div class="content-floor">
                    <span class="key">班级名称</span>
                    <el-input class="circular-bead-input" v-model="classEdit.name"></el-input>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button round @click="handleDialogClose">取 消</el-button>
                <el-button round type="primary" @click="handleEditClass">确 定</el-button>
            </span>
        </el-dialog> -->
    </div>
</template>

<script>
import Core from '@/core';

export default {
    components: {
        NoData: () => import('@/components/Empty.vue')
    },
    props: {
        schoolId: {
            type: [String, Number]
        },
        schoolName: {
            type: [String]
        }
    },
    data() {
        return {
            userType: Core.Data.getUserType(),
            USER_TYPE: Core.Const.USER_TYPE,

            total: 0,
            currentPage: 1,
            pageSize: 10,
            dialogVisible: false,
            tableData: [],
            classEdit: {
                id: '',
                name: '',
            },
            search: {
                name: '',
            },
        };
    },
    created() {
        this.getTableData();
    },
    methods: {
        auth: Core.Utils.auth,
        pageChange(page) { // 页码改变
            this.currentPage = page;
            this.getTableData();
        },
        pageSizeChange(size) {
            this.pageSize = size;
            this.getTableData();
        },
        getTableData() { // 获取班级列表
            Core.Api.SchoolClass.list(
                this.currentPage,
                this.pageSize,
                this.schoolId,
                this.search.name,
            ).then((res) => {
                this.total = res.count;
                this.tableData = res.list;
            });
        },
        handleDeleteClass(id) { // 删除 班级
            this.$confirm('确定要删除这个班级吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                Core.Api.SchoolClass.delete(id).then((res) => {
                    this.$message.success('删除成功!');
                    this.pageChange(1)
                })
            })
        },
    /*
        handleDialogShow(item) { // 显示弹框
            this.dialogVisible = true
        },
        handleDialogClose() { // 关闭弹框
            this.dialogVisible = false
        },
        handleEditClass(item) { //弹框确认 添加/修改 班级
            if (item) {
                console.log("handleEditClass -> item", item)
            }
            this.dialogVisible = false
        },
    */
        routerChange(key, q = '') {  // 路由改变
            let routeUrl
            switch (key) {
                case 1:
                    routeUrl = this.$router.resolve({
                        path: '/class/class-detail',
                        query: {
                            school_id: this.schoolId,
                            school_name: this.schoolName,
                            class_id: q.id
                        }
                    })
                    break;
            }
            if (this.auth('ADMIN')) {
                window.open(routeUrl.href, '_blank')
            } else {
                window.open(routeUrl.href, '_self')
            }
        }
    }
};
</script>
<style lang="scss" scoped>
.container {
    @import '@/common/styles/table.scss';
    .info-container{
        @include flex(row, space-between, center);
    }
    .dialog-createBySchool {
        .dialog-content {
            .content-floor {
                display: flex;
                align-items: center;
                margin-bottom: 20px;
                .key {
                    display: block;
                    width: 120px;
                }
                .el-select {
                    width: 100%;
                }
            }
        }
    }
}
</style>